.root {
  max-width: 100%;
  overflow: scroll;
}
.rootLandingPage {
  max-width: 100%;
  /* overflow: scroll ; */
}
.root table {
  width: 100%;

  margin-top: 1rem;
  border-collapse: collapse;
  white-space: pre-wrap;
}

.root td,
.root th {
  border: 1px solid lightgray;
  padding: 8px;
}

.root tr {
  background-color: #ffff;
  font-size: 14px;
}
.root tbody {
  vertical-align: baseline;
}
.root th {
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 14px;
  text-align: center;
  background-color: var(--marketplaceColorDark);
  color: white;
}
