.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.backgroundImage {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.backgroundImageDesktop {
  display: none;
  cursor: pointer;
  @media (min-width: 768px) {
    display: block;
    /* object-fit: cover; */
    width: 100%;
    height: 100%;
  }
}
.backgroundImageMobile {
  display: block;
  /* object-fit: cover; */
  width: 100%;
  cursor: pointer;
  /* height: 100%; */
  @media (min-width: 768px) {
    display: none;
  }
}

.backgroundOverlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
