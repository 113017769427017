@import '../../../../styles/customMediaQueries.css';

.root {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  min-height: 350px;
  &:nth-of-type(odd) {
    background-color: var(--colorWhite);
  }
}

.sectionContent {
  padding: 32px 0;
  position: relative;
  margin-top: 0rem;
  margin-right: auto;
  /* margin-left: 1rem; */
  @media (--viewportMedium) {
    padding: 64px 0;
    margin-top: 8rem;
    align-self: flex-end;
    margin: 0;
  }
}
.sectionContentFooter {
  padding-top: 5px;
  position: relative;
  margin-right: auto;
  background-color: #dcc8e1;
  /* margin-left: 1rem; */
  @media (--viewportMedium) {
    padding-top: 26px;
    margin-top: 1rem;
    align-self: flex-end;
    margin: 0;
  }
}

.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
