.root {
  /* SectionContainer defaults */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  /* Hero specific */
  /* min-height: 100vh; */
  aspect-ratio: 4/4;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    /* min-height: 80vh; */
    min-height: auto;
    aspect-ratio: 11/4;
  }
}
