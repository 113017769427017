@import '../../../../styles/customMediaQueries.css';

.baseColumn {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;
  margin: 0 auto;
  padding: 32px;
  @media (--viewportXLarge) {
    gap: 32px;
    max-width: 1800px;
  }
}

.oneColumn {
  composes: baseColumn;
}

.twoColumns {
  composes: baseColumn;
  grid-template-columns: repeat(2, calc((100% - 20px) / 2));
  @media (--viewportMedium) {
    grid-template-columns: repeat(2, calc((100% - 20px) / 2));
  }
}
.threeColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(3, calc((100% - 2 * 10px) / 3));
  }
}
.fourColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(4, calc((100% - 3 * 10px) / 4));
  }
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}
