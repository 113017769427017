.markdownImage {
  /**
   * Note: markdown images might be too small for filling the space.
   */
  width: auto;
  max-width: 100%;
  border-radius: 8px;
  object-fit: cover;
}

.fieldImage {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}
.fieldImageLandingPage {
  width: 100%;
  height: 100%;

  border-radius: 8px;
  object-fit: cover;

  @media (--viewportMedium) {
    height: 280px;
  }
  &:hover {
    cursor: pointer;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  }
}
